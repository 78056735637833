import React, {useEffect, useState} from "react";
import {Container, Nav, Navbar} from "react-bootstrap";
import axios, {AxiosResponse} from "axios";
import "./FooterNavbar.scss";

export const FooterNavbar = () => {
    const [menuLinks, setMenuLinks] = useState<any[]>([]);

    useEffect(() => {
        axios
            .get('/menu_link/', {
                params: {
                    menu_type: 'footer'
                }
            })
            .then((resp: AxiosResponse) => {
                setMenuLinks(resp.data)
            })
    }, []);

    return (
        <Container className="footer-navbar">
            <Navbar>
                <Container>
                    <Navbar.Brand href="#home"></Navbar.Brand>
                    <Nav className="d-none d-lg-flex">
                        {menuLinks.map((menuLink) => {
                            return (
                                <Nav.Link style={{color: menuLink.color}} key={menuLink.id}
                                          href={menuLink.url}>{menuLink.name}</Nav.Link>
                            );
                        })}
                    </Nav>
                    <span className="d-block d-lg-none">MojeGry.pl &copy; 2022</span>
                </Container>
            </Navbar>
        </Container>
    );
}