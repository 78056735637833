import React, {useEffect, useState} from 'react';
import {Col, Container, Row, Modal, Form, Button, Alert} from "react-bootstrap";
import {PageLayout} from "../Layout/PageLayout";
import {CategoryInterface, GameInterface, TagInterface, ReviewInterface} from "../../interfaces";
import {useNavigate, useParams} from "react-router-dom";
import axios, {AxiosResponse} from "axios";
import Header from "../Text/Header";
import HeaderH2 from "../Text/HeaderH2";
import {GameTile} from "../Blocks/GameTile/GameTile";
import {CategoryTile} from "../Blocks/CategoryTile/CategoryTile";
import {TextBlock} from "../Blocks/TextBlock/TextBlock";
import videoGamesImg from "../../assets/video_games.png";
import {ActionButton} from "../Button/Action/ActionButton";
import {MetaTags} from "../Seo/MetaTags/MetaTags";
import {ErrorGame} from "../Blocks/ErrorGame/ErrorGame";
import {GameIFrame} from "../Blocks/GameIFrame/GameIFrame";




const getWebsiteSchema = () => ({
    "@context": "https://schema.org",
    "@type": "WebSite",
    "name": "MojeGry.pl",
    "url": "https://mojegry.pl",
    "image": "https://mojegry.pl/static/media/logo.b270c0b8d98f8bf7cd1a.png",  // Update the image URL accordingly
    "potentialAction": {
        "@type": "SearchAction",
        "target": "https://mojegry.pl/szukaj/{search_term_string}",
        "query-input": "required name=search_term_string"
    }
});

const getWebApplicationSchema = (game: GameInterface) => ({
    "@context": "https://schema.org",
    "@type": "WebApplication",
    "name": game.name,
    "url": `https://mojegry.pl/${game.slug}`,  // Ensure this matches your actual URL structure
    "applicationCategory": "Game",
    "operatingSystem": "Windows, MacOS, Linux, iOS, Android",
    "offers": {
        "@type": "Offer",
        "priceCurrency": "EUR",
        "availability": "https://schema.org/InStock",
    },
    "applicationSubCategory": game.categories.map(cat => cat.name).join(', '),
    "image": game.image // Make sure this is the correct path to your game's image
});

const convertReviewsToSchema = (reviews: ReviewInterface[], gameName: string) => ({
    "@context": "https://schema.org",
    "@type": "AggregateRating",
    "itemReviewed": {
        "@type": "VideoGame",
        "name": gameName
    },
    "ratingValue": reviews.reduce((acc, review) => acc + review.rating, 0) / reviews.length || 0,
    "reviewCount": reviews.length,
    "reviews": reviews.map(review => ({
        "@type": "Review",
        "author": {
            "@type": "Person",
            "name": review.username
        },
        "reviewRating": {
            "@type": "Rating",
            "ratingValue": review.rating,
            "bestRating": "5",
            "worstRating": "1"
        },
        "reviewBody": review.comment
    }))
});









type Props = {}

export const GamePage = (props: Props) => {
    const [game, setGame] = useState<GameInterface>();
    const [games, setGames] = useState<GameInterface[]>([]);
    const [tags, setTags] = useState<TagInterface[]>([]);
    const [show, setShow] = useState(false);
    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);
    const [reviews, setReviews] = useState<ReviewInterface[]>([]);
    const [username, setUsername] = useState('');
    const [comment, setComment] = useState('');
    const [rating, setRating] = useState(0);
    const [reviewSubmitted, setReviewSubmitted] = useState(false);
const renderStars = (rating: number): string => {
  const maxStars = 5;
  let fullStars = "★".repeat(Math.floor(rating));
  let halfStar = rating % 1 !== 0 ? "½" : ""; // Opcjonalnie, użyj jakiegoś innego symbolu dla pół gwiazdy
  let emptyStars = "☆".repeat(maxStars - Math.floor(rating) - (halfStar ? 1 : 0));

  return fullStars + halfStar + emptyStars;
};



    let navigate = useNavigate();
    let params = useParams();








const websiteSchema = getWebsiteSchema();
    const reviewSchema = game && reviews.length ? convertReviewsToSchema(reviews, game.name) : null;
    const gameSchema = game ? getWebApplicationSchema(game) : null;

useEffect(() => {
    // Explicitly type scriptTags as an array of HTMLScriptElement
    const scriptTags: HTMLScriptElement[] = [];

    const addSchema = (schema: object) => {
        const scriptTag = document.createElement("script");
        scriptTag.type = "application/ld+json";
        scriptTag.text = JSON.stringify(schema);
        document.head.appendChild(scriptTag);
        scriptTags.push(scriptTag);
    };

    addSchema(websiteSchema);

    if (gameSchema) {
        addSchema(gameSchema);
    }

    if (reviewSchema) {
        addSchema(reviewSchema);
    }

    if (game) {
        const linkTag = document.createElement('link');
        linkTag.rel = 'canonical';
        linkTag.href = `https://mojegry.pl/gra/${game.slug}`;  // Ensure this matches your actual URL structure
        document.head.appendChild(linkTag);
        scriptTags.push(linkTag as unknown as HTMLScriptElement); // Temporarily cast linkTag if keeping a consistent array or create a separate array for links
    }

    return () => {
        scriptTags.forEach(scriptTag => {
            document.head.removeChild(scriptTag);
        });
    };
}, [websiteSchema, gameSchema, reviewSchema, game]);









    useEffect(() => {
        if (params.slug) {
            axios
                .get(`/game/${params.slug}`)
                .then((resp: AxiosResponse) => {
                    setGame(resp.data);
                }).catch((error) => {
                navigate('/404');
            });
        }
    }, [params]);

   


   useEffect(() => {
    if (game) {
        console.log(`Pobieranie recenzji dla gry o ID: ${game.id}`);
        axios.get(`/reviews/`, { params: { game: game.id } })
            .then((resp: AxiosResponse) => {
                console.log('Odpowiedź API:', resp.data); 
                setReviews(resp.data);  
                console.log('Ustawione recenzje:', resp.data);  
            })
            .catch((error) => {
                console.error('Błąd przy pobieraniu recenzji:', error);
            });
    }
}, [game]);





    useEffect(() => {
        if (game) {
            axios
                .get('/game/', {
                    params: {
                        categories: game.categories[0].id,
                        limit: 8,
                        rand: true
                    }
                })
                .then((resp: AxiosResponse) => {
                    setGames(resp.data.results);
                })

            var related: TagInterface[] = [];
            game.tags.forEach((tag) => {
                tag.related.forEach((rTag) => {
                    if (related.findIndex(x => x.id == rTag.id) === -1) {
                        related.push(rTag);
                    }
                })
            })
            setTags(related);
        }
    }, [game])

    const handleReviewSubmit = (e: React.FormEvent) => {
    e.preventDefault();

    // Show the submitted message immediately
    setReviewSubmitted(true);
    
    // Optionally, clear the form fields
    setUsername('');
    setComment('');
    setRating(0);

    // Send the data to your backend
    axios.post('/reviews/create', {
        gameId: game?.id,
        username,
        comment,
        rating,
    }).then((response) => {
        console.log('Review submitted:', response.data);
    }).catch((error) => {
        console.error('Error submitting review:', error);
    });
};

    return (
        <>
            {game && (
                <>
                    <MetaTags title={game.meta_title ? game.meta_title : `${game.name} 🎮 - mojegry.pl`} description={game.meta_description}/>
                    <PageLayout>
                        <Container>
                            <Row>
                                <Col className="d-none d-lg-block" xl="2">
                                    {games.length && (
                                        games.slice(0, 4).map((game) => {
                                            return (
                                                <Row key={game.id}>
                                                    <Col>
                                                        <GameTile game={game}/>
                                                    </Col>
                                                </Row>
                                            );
                                        }))
                                    }
                                </Col>
                                <Col xl="8">
                                    <Header headerLocation="gamePage" textSecond={game.name}/>
                                    {game.iframe_url ? (
                                        <div className="iframe-wrapper mb-3 mb-lg-5">
                                            <GameIFrame game={game} />
                                        </div>
                                    ) : (
                                        <div className="mb-3 mb-lg-5">
                                            <ErrorGame game={game}/>
                                        </div>
                                    )}
                                    <Row>
                                        <Col className="d-flex justify-content-between">
                                            <div className="game-attributes"></div>
                                            <ActionButton icon="controller" action={handleShow}>Jak grać?</ActionButton>
                                            <Modal show={show} onHide={handleClose}>
                                                <Modal.Header closeButton>
                                                    <span className="modal-heading">Jak grać?</span>
                                                </Modal.Header>
                                                <Modal.Body className="modal-content">{game.instructions}</Modal.Body>
                                            </Modal>
                                        </Col>
                                    </Row>
                                </Col>
                                <Col className="d-none d-lg-block" xl="2">
                                    {games.length && (
                                        games.slice(4, 8).map((game) => {
                                            return (
                                                <Row key={game.id}>
                                                    <Col>
                                                        <GameTile game={game}/>
                                                    </Col>
                                                </Row>
                                            );
                                        }))
                                    }
                                </Col>
                            </Row>
                        </Container>







                       


                        <Container>
                            <Row>
                                <Col>
                                    <HeaderH2 textFirst="Te kategorie" textSecond="mogą Ci się spodobać"/>
                                </Col>
                            </Row>
                            {tags && (
                                <Row className="mb-5">
                                    {tags.map((tag) => {
                                        return (
                                            <Col xs="6" lg="3" className="px-2" key={tag.id}>
                                                <CategoryTile category={tag} tag/>
                                            </Col>
                                        );
                                    })}
                                </Row>
                            )}
                        </Container>
                        {(game.title || game.description) &&
                        <Container>
                            <TextBlock title={game.title ?? ''} content={game.description ?? ''} image={videoGamesImg}
                                       colored/>
                        </Container>
                          }

                        <Container>
  <HeaderH2 textFirst="Recenzje użytkowników" textSecond={game ? game.name : ''} />
  <Row>
    {Array.isArray(reviews) && reviews.length > 0 ? (
      reviews.map((review) => (
        <Col key={review.id} className="mb-4" xs={12}>
          <div className="d-flex border rounded p-3">
            <div className="review-info flex-grow-1">
              <strong>{review.username}</strong>
              <p>{review.comment}</p>
            </div>
            <div className="review-rating">
              {renderStars(review.rating)}
            </div>
          </div>
        </Col>
      ))
    ) : (
      <Col>
        <p>Brak recenzji dla tej gry.</p>
      </Col>
    )}
  </Row>

 <Row className="mt-5">
    <Col>
        <HeaderH2 textFirst="Dodaj" textSecond="swoją opinię" />
        {reviewSubmitted && <Alert variant="success" onClose={() => setReviewSubmitted(false)} dismissible>Opinia została wysłana!</Alert>}
        <Form onSubmit={handleReviewSubmit}>
            <Form.Group className="mb-3" controlId="reviewUsername">
                <Form.Label>Nick</Form.Label>
                <Form.Control type="text" placeholder="Wpisz swój nick" value={username} onChange={(e) => setUsername(e.target.value)} required />
            </Form.Group>
            <Form.Group className="mb-3" controlId="reviewComment">
                <Form.Label>Komentarz</Form.Label>
                <Form.Control as="textarea" rows={3} placeholder="Wpisz swoją opinię" value={comment} onChange={(e) => setComment(e.target.value)} required />
            </Form.Group>
            <Form.Group className="mb-3" controlId="reviewRating">
                <Form.Label>Ocena</Form.Label>
                <Form.Control as="select" value={rating} onChange={(e) => setRating(Number(e.target.value))} required>
                    <option value={0}>Wybierz ocenę</option>
                    {[1, 2, 3, 4, 5].map((rating) => (
                        <option key={rating} value={rating}>{renderStars(rating)} ({rating})</option>
                    ))}
                </Form.Control>
            </Form.Group>
            <Button variant="primary" type="submit">Dodaj opinie</Button>
        </Form>
    </Col>
</Row>



</Container>



                        
                    </PageLayout>
                </>
            )}
        </>
    );
}